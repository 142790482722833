<template>
  <div class="container">
    <edit-header
      entity="user"
      :error.sync="error"
      :error-message="errorMessage"
      :invalid.sync="invalid"
      :invalid-message="invalidMessage"
      :submitted.sync="submitted"
      :isNew="isNew"></edit-header>
    <div v-if="resetPasswordSubmitted" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="resetPasswordSubmitted = false"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> An email has been sent to recover the user's password.
    </div>
    <div v-if="resetPasswordError" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="resetPasswordError = false"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> Unable to send password recovery email.
    </div>
    <div v-if="magicLinkSent === true" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="magicLinkSent = null">
      </button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> Login link sent successfully
    </div>
    <div v-if="magicLinkSent === false" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="magicLinkSent === false"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> An error has occurred while sending Login link
    </div>
    <form
      v-disable-all="!can(uiPermissions.CUSTOMERS_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">

        <div class="col">
          <div data-test="card-user-info" class="card">
            <div class="card-header">
              <h3 class="card-title">{{isNew ? 'User' : displayName(item.user)}}</h3>
              <div class="card-options d-flex gap-10">
                <router-link
                  v-if="item.user.id && can(uiPermissions.SUPER_ADMIN)"
                  :to="`/users/${item.user.id}/roles-permissions`"
                  class="btn btn-primary"
                  data-test="btn-roles-permissions">
                  Roles & Permissions
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label class="form-label">First name</label>
                    <input
                      v-model="item.user.firstName"
                      data-test="txt-firstname"
                      type="text"
                      :class="`form-control ${errors.has('firstName') ? 'is-invalid' : ''}`">
                    <div v-if="errors.has('firstName')" class="invalid-feedback">{{errors.first('firstName')}}</div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Last name</label>
                    <input
                      v-model="item.user.lastName"
                      data-test="txt-lastname"
                      type="text"
                      :class="`form-control ${errors.has('lastName') ? 'is-invalid' : ''}`">
                    <div v-if="errors.has('lastName')" class="invalid-feedback">{{errors.first('lastName')}}</div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Nickname</label>
                    <input
                      v-model="item.user.nickname"
                      data-test="txt-nickname"
                      type="text"
                      :class="`form-control ${errors.has('nickname') ? 'is-invalid' : ''}`">
                    <div v-if="errors.has('nickname')" class="invalid-feedback">{{errors.first('nickname')}}</div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Date of birth</label>
                    <input
                      v-model="item.user.birthDate"
                      data-test="txt-birthdate"
                      :class="`form-control ${errors.has('birthDate') ? 'is-invalid' : ''}`"
                      placeholder="DD/MM/YYYY">
                    <div v-if="errors.has('birthDate')" class="invalid-feedback">{{errors.first('birthDate')}}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label class="form-label">Email address</label>
                    <input
                      v-model="item.user.email"
                      data-test="txt-email"
                      type="email"
                      :class="`form-control ${errors.has('email') ? 'is-invalid' : ''}`">
                    <div v-if="errors.has('email')" class="invalid-feedback">{{errors.first('email')}}</div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Phone number</label>
                    <input
                      v-model="item.user.phone"
                      data-test="txt-phone"
                      type="tel"
                      :class="`form-control ${errors.has('phone') ? 'is-invalid' : ''}`">
                    <div v-if="errors.has('phone')" class="invalid-feedback">{{errors.first('phone')}}</div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Nationality</label>
                    <select
                      v-model="item.user.nationality"
                      name="nationality"
                      class="form-control"
                      autocomplete="nationality">
                      <option :value="null" disabled>Nationality</option>
                      <option
                        v-for="data in nationalities"
                        :key="data"
                        :value="data">
                        {{data}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Password</label>
                    <template v-if="!isNew">
                      <button
                        v-if="can(uiPermissions.CUSTOMERS_FORGOT_PASSWORD_SEND)"
                        type="button"
                        data-test="btn-reset-pwd"
                        class="btn btn-secondary"
                        @click="resetPassword">
                        Send reset password email
                      </button>
                      <button
                        v-if="can(uiPermissions.CUSTOMERS_MAGIC_LINK_SEND)"
                        type="button"
                        data-test="btn-login-link"
                        class="btn btn-primary ml-2"
                        @click="sendLoginLink">
                        Send Login Link
                      </button>
                    </template>
                    <input
                      v-else
                      v-model="item.user.password"
                      data-test="txt-password"
                      type="password"
                      :class="`form-control ${errors.has('password') ? 'is-invalid' : ''}`"/>
                    <div v-if="errors.has('password')" class="invalid-feedback">{{errors.first('password')}}</div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col mb-0">
                  <label class="form-label">Roles</label>
                  <div class="selectgroup selectgroup-pills">
                    <label
                      v-for="role in item.user.roles"
                      :key="role"
                      class="selectgroup-item">
                      <input
                        type="checkbox"
                        :disabled="true"
                        class="selectgroup-input">
                      <span class="selectgroup-button">{{role}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="!isNew" class="row">

        <div class="col">
          <div data-test="card-user-subscription" class="card">
            <div class="card-body">
              <div data-test="label-subscription" class="row">

                <div class="col-6 col-sm-4">
                  {{item.subscriptions.filter(subscription => subscription.status ===
                    SubscriptionStatuses.Active).length}} active <a
                    :href="`/subscriptions?query=${item.user.email}`">subscription(s)</a>
                </div>
                <div class="col-6 col-sm-4">
                  AED {{numeral(item.storeCredit).format('0,0[.]00')}} <a
                    :href="`/store-credit?query=${item.user.email}`">store credit</a> (AED
                  {{numeral(item.storeCreditUsed).format('0,0[.]00')}} used)
                </div>
                <div class="col-6 col-sm-4 mt-3 mt-sm-0">
                  AED {{numeral(item.referralCredit).format('0,0[.]00')}} <a
                    :href="`/referrals?query=${item.user.email}`">referral credit</a> (AED
                  {{numeral(item.referralCreditUsed).format('0,0[.]00')}} used)
                </div>

              </div>
            </div>
            <div class="card-body">
              <p>
                Referral link: <a tabindex="0" @click.prevent="copy(item.referralLink || 'none')">{{item.referralLink ||
                  'none'}}</a> (click to copy)
              </p>
              <p>Client ID: {{item.user.clientId}}</p>
            </div>
            <div
              v-if="!(item.user.permissions || []).includes(uiPermissions.SUPER_ADMIN) && can([uiPermissions.CUSTOMERS_LOGIN_AS])"
              class="card-footer">
              <button
                type="button"
                data-test="btn-switch-to-user"
                class="btn btn-secondary"
                :disabled="submitting"
                @click="switchTo">
                Switch to user
              </button>
            </div>
          </div>
        </div>

      </div>
      <div class="row row-cards row-deck">

        <div class="col-lg-6">
          <div data-test="card-user-delivery-address" class="card">
            <div class="card-header">
              <h3 class="card-title">Delivery address</h3>
            </div>
            <div class="card-body">
              <delivery-address v-model="item.deliveryAddress"/>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div data-test="card-user-billing-address" class="card">
            <div class="card-header">
              <h3 class="card-title">Billing address</h3>
              <div class="card-options">
                <label class="custom-switch m-0">
                  <input
                    v-model="item.billingAddressEqualsDelivery"
                    type="checkbox"
                    class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">Same as delivery address</span>
                </label>
              </div>
            </div>
            <div class="card-body">
              <billing-address v-if="!item.billingAddressEqualsDelivery" v-model="item.billingAddress"/>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="user"
            name="users"
            :isNew="isNew"
            :submitting="submitting"
            hide-delete="true"></edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import BillingAddress from '@/components/BillingAddress.vue';
import DeliveryAddress from '@/components/DeliveryAddress.vue';
import EditFooter from '@/components/EditFooter.vue';
import EditHeader from '@/components/EditHeader.vue';
import edit from '@/mixins/edit';
import {auth, customers} from '@/services';
import SubscriptionStatuses from '@hellochef/shared-js/enums/SubscriptionStatuses';
import {flattenForPatch} from '@hellochef/shared-js/helpers';
import submitting from '@hellochef/shared-js/mixins/submitting';
import Customers from '@hellochef/shared-js/services/Customers';
import Countries from '@hellochef/shared-js/enums/Countries';
import moment from 'moment';

export default {
  components: {
    BillingAddress,
    DeliveryAddress,
    EditFooter,
    EditHeader,
  },
  mixins: [
    edit,
    submitting,
  ],
  data() {
    return {
      changes: {
        user: [],
      },
      SubscriptionStatuses,
      item: {
        billingAddress: Customers.getAddressDefaults(),
        billingAddressEqualsDelivery: true,
        deliveryAddress: Customers.getAddressDefaults(),
        subscriptions: [],
        user: {
          password: '',
          legacy_roles: [],
        },
      },
      nationalities: Countries || [],
      resetPasswordError: false,
      resetPasswordSubmitted: false,
      magicLinkSent: null,
      validator: this.$bodyValidator,
    };
  },
  computed: {
    errors() {
      return this.validator.errors();
    },
    route() {
      return `/users/${this.item.id}`;
    },
  },
  methods: {

    fetchData(id) {
      return customers.getById(id);
    },
    transformData(result) {
      // new customer creation returns response differently
      if (this.isNew) {
        if (result.customer.user.birthDate && !moment(result.customer.user.birthDate, 'DD/MM/YYYY', true).isValid()) {
          result.customer.user.birthDate = null;
        }

        return {item: result.customer};
      }

      if (result.item.user.birthDate && !moment(result.item.user.birthDate, 'DD/MM/YYYY', true).isValid()) {
        result.item.user.birthDate = null;
      }

      return result;
    },
    async resetPassword() {
      this.resetPasswordError = false;
      this.resetPasswordSubmitted = false;

      try {
        await auth.forgotPassword(this.item.user);
        this.resetPasswordSubmitted = true;
      }
      catch (ex) {
        this.resetPasswordError = true;
        throw ex;
      }
      finally {
        this.$store.dispatch('events/track', {
          event: 'Admin Send Password Reset',
          customer: this.item,
          properties: {},
        });

        window.scrollTo(0, 0);
      }
    },
    validate() {
      this.validator
        .setData(this.item.user)
        .setRules({
          email: 'required|email',
          firstName: 'required|string|min:1',
          lastName: 'required|string|min:1',
          password: 'sometimes|required|string|min:5',
          phone: 'sometimes|string|emirates_number',
          birthDate: ['nullable', (value, fail) => {
            if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
              fail('The :attribute does not match the format DD/MM/YYYY');
            }
          }],
          nickname: 'nullable|string|min:3',
        });

      if (!this.validator.validate()) {
        return false;
      }

      return true;
    },
    submitData(item) {
      item.user.birthDate = item.user.birthDate ? moment(item.user.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null;

      if (item.billingAddressEqualsDelivery) {
        Object.assign(item.billingAddress, item.deliveryAddress);
      }
      else {
        // always match phone numbers
        item.billingAddress.billingAddressAlternatePhone = item.deliveryAddress.billingAddressAlternatePhone;
      }

      const payload = Object.assign({}, item,

        item.user,
        flattenForPatch(item, 'billingAddress'),
        flattenForPatch(item, 'deliveryAddress'),
        {id: item.id},
      );

      delete payload.billingAddress;
      delete payload.deliveryAddress;
      delete payload.subscriptions;
      delete payload.user;

      this.$store.dispatch('events/track', {
        event: 'Admin Update User Details',
        customer: this.item,
        properties: {
          changes: this.changes,
        },
      });

      return customers.saveOrUpdate(payload);
    },
    async switchTo() {
      if (window.confirm(`This will sign you out on the website and sign-in as ${this.item.user.email}. Do you want to continue?`)) {
        this.submitting = true;

        try {
          const response = await auth.loginAs(this.item.id);
          window.open(response.loginLink, '_blank');
        }
        finally {
          this.submitting = false;

          this.$store.dispatch('events/track', {
            event: 'Admin Switch To User',
            customer: this.item,
            properties: {},
          });
        }
      }
    },
    async sendLoginLink() {
      if (window.confirm(`This will send a login email to ${this.item.user.email}. Do you want to continue?`)) {
        this.magicLinkSent = null;
        try {
          await auth.sendMagicLink({email: this.item.user.email});
          this.magicLinkSent = true;
        }
        catch (e) {
          this.magicLinkSent = false;
        }
      }
    },
  },
};

</script>
