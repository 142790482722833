<template>
  <div class="form-row">
    <div class="form-group col-9">
      <label class="form-label">Street name</label>
      <input
        type="text"
        data-test="txt-street"
        class="form-control"
        :value="value.streetName"
        @input="setField('streetName', $event.target.value)"/>
    </div>
    <div class="form-group col-3">
      <label class="form-label">Flat/House No.</label>
      <input
        type="text"
        data-test="txt-flat"
        class="form-control"
        placeholder="123"
        :value="value.number"
        @input="setField('number', $event.target.value)"/>
    </div>
    <div class="form-group col-12">
      <label class="form-label">Details</label>
      <input
        type="text"
        class="form-control"
        data-test="txt-building"
        placeholder="Building name"
        :value="value.buildingName"
        @input="setField('buildingName', $event.target.value)"/>
      <input
        type="text"
        class="form-control mt-2"
        data-test="txt-development"
        placeholder="Development name"
        :value="value.developmentName"
        @input="setField('developmentName', $event.target.value)"/>
      <input
        type="text"
        class="form-control mt-2"
        data-test="txt-area"
        placeholder="Area"
        :value="value.area"
        @input="setField('area', $event.target.value)"/>
    </div>
    <div class="form-group col-sm-6">
      <label class="form-label">City</label>
      <select
        class="form-control custom-select"
        data-test="txt-city"
        :value="value.city"
        @input="setField('city', $event.target.value)">
        <option :value="null" disabled>Select city</option>
        <option
          v-for="item in cities"
          :key="item.name"
          :value="item.name">
          {{item.name}}
        </option>
      </select>
    </div>
    <div class="form-group col-sm-6">
      <label class="form-label">Country</label>
      <select
        class="form-control custom-select"
        data-test="txt-country"
        :value="value.country"
        disabled
        @input="setField('country', $event.target.value)">
        <option :value="null" disabled>Select country</option>
        <option
          v-for="[code, name] in Array.from(TelrCountries)"
          :key="code"
          :value="code">
          {{name}}
        </option>
      </select>
    </div>
    <div class="form-group col-6">
      <label class="form-label">Latitude</label>
      <input
        type="text"
        data-test="txt-latitude"
        class="form-control"
        :value="value.lat"
        @input="setField('lat', $event.target.value)"/>
    </div>
    <div class="form-group col-6">
      <label class="form-label">Longitude</label>
      <input
        type="text"
        data-test="txt-longitude"
        class="form-control"
        :value="value.lng"
        @input="setField('lng', $event.target.value)"/>
    </div>
    <div v-if="value.full_address" class="form-group col-12 mb-2">
      <label class="form-label">Address link</label>
      <a :href="googleMapsLink" target="_blank">{{value.full_address}}</a>
    </div>
    <div class="form-group col-12 mb-0">
      <label class="form-label">Other</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Alt. phone</span>
        </div>
        <input
          type="tel"
          data-test="txt-alternate-phone"
          class="form-control"
          :value="value.alternatePhone"
          @input="setField('alternatePhone', $event.target.value)"/>
      </div>
      <div class="input-group mt-2">
        <div class="input-group-prepend">
          <span class="input-group-text">Note</span>
        </div>
        <input
          type="text"
          data-test="txt-note"
          class="form-control"
          :value="value.note"
          @input="setField('note', $event.target.value)"/>
      </div>
    </div>
  </div>
</template>

<script>
import {deliveryCities} from '@/services';
import TelrCountries from '@hellochef/shared-js/enums/TelrCountries';

export default {
  props: [
    'value',
  ],
  data() {
    return {
      TelrCountries,
      cities: [],
    };
  },
  computed: {
    googleMapsLink() {
      return `https://maps.google.com/?q=${this.value.lat},${this.value.lng}`;
    },
  },
  async created() {
    const {items} = await deliveryCities.getByParameters();
    this.cities = items;
  },
  methods: {
    setField(field, value) {
      this.$emit('input', Object.assign({}, this.value, {
        [field]: value,
      }));
    },
  },
};
</script>
