<template>
  <div class="form-row">
    <div class="form-group col-12">
      <label class="form-label">Address</label>
      <input
        type="text"
        class="form-control"
        data-test="txt-address-line1"
        :value="value.streetName"
        @input="setField('streetName', $event.target.value)"/>
      <input
        type="text"
        class="form-control mt-2"
        data-test="txt-address-line2"
        placeholder="123"
        :value="value.number"
        @input="setField('number', $event.target.value)"/>
    </div>
    <div class="form-group col-sm-6">
      <label class="form-label">City</label>
      <input
        type="text"
        class="form-control"
        data-test="txt-billing-city"
        :value="value.city"
        @input="setField('city', $event.target.value)"/>
    </div>
    <div class="form-group col-sm-6">
      <label class="form-label">Country</label>
      <select
        class="form-control custom-select"
        data-test="txt-billing-country"
        :value="value.country"
        @input="setField('country', $event.target.value)">
        <option :value="null" disabled>Select country</option>
        <option
          v-for="[code, name] in Array.from(TelrCountries)"
          :key="code"
          :value="code">
          {{name}}
        </option>
      </select>
    </div>
    <div class="form-group col-12 mb-0">
      <label class="form-label">Other</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Alt. email</span>
        </div>
        <input
          type="email"
          class="form-control"
          data-test="txt-alternate-email"
          :value="value.alternateEmail"
          @input="setField('alternateEmail', $event.target.value)"/>
      </div>
    </div>
  </div>

</template>

<script>
import TelrCountries from '@hellochef/shared-js/enums/TelrCountries';

export default {
  props: [
    'value',
  ],
  data() {
    return {
      TelrCountries,
    };
  },
  methods: {
    setField(field, value) {
      this.$emit('input', Object.assign({}, this.value, {
        [field]: value,
      }));
    },
  },
};
</script>
